<template>
    <div :key="pageKey">
        <div class="d-flex flex-row align-center pt-3" v-if="isAuthorized == false">
            <v-alert border="left" dense icon="warning" class="mb-0 white--text" color="warning">{{ $t('message.xeroConnectionTerminated') }}</v-alert>
            <v-btn @click="reconnectToXero()" class="ml-3 info">{{ $t('message.reconnectXero') }}</v-btn>
        </div>
        <v-data-table
            :headers="contactHeaders"
            :items="PendingContacts"
            :items-per-page="-1"
            :search="contactSearchTerm"
            calculate-widths
            class="pt-3 appic-table-light specification-table"
            dense
            item-key="Contact.id"
            show-select
            single-select
            v-model="selectedContacts"
        >
            <template v-slot:top>
                <div class="d-flex flex-row align-center">
                    <span class="font-weight-bold">{{ $t('message.buyers') }}</span>
                </div>
                <div class="d-flex flex-row mb-3">
                    <v-text-field
                        :value="contactSearchTerm"
                        append-icon="search"
                        class="filter-width-250"
                        dense
                        hide-details="auto"
                        solo
                        @change="contactSearchTerm = $event"
                    ></v-text-field>
                    <v-btn class="ml-3" @click="resetContactSearch">{{ $t('message.clear') }}</v-btn>
                    <v-btn class="ml-3 info lighten-2 text--darken-1 black--text" @click="uploadContactsToXero()" :disabled="isAuthorized == false">{{ $t('message.integrateSelection') }}</v-btn>
                </div>
            </template>
            <template v-slot:item.Contact.Addresses="{ item }">
                <template v-for="(address, index) in item.Contact.Addresses">
                    <div class="d-flex flex-row" :key="index">
                        <span>{{ address.AddressType + ': ' +  (address.AddressLine1 != null ? address.AddressLine1.trim() : '') + (address.AddressLine2 != null ? address.AddressLine2.trim() : '') + (address.AddressLine3 != null ? address.AddressLine3.trim() : '') + (address.City != null ? address.City.trim() : '')  + (address.Region != null ? address.Region.trim() : '') + (address.PostalCode != null ? address.PostalCode.trim() : '')  + (address.Country != '' ? address.Country.trim() : '') }}</span>
                    </div>
                </template>
            </template>
            <template v-slot:item.Contact.ContactPersons="{ item }">
                <template v-for="(contactPerson, index) in item.Contact.ContactPersons">
                    <div class="d-flex flex-row" :key="index">
                        <span>{{ contactPerson.FirstName != null ? contactPerson.FirstName.trim() : '' }}</span>
                        <span class="ml-2">{{ '(' + (contactPerson.EmailAddress != null ? contactPerson.EmailAddress.trim() : '') + ')' }}</span>
                    </div>
                </template>
            </template>
            <template v-slot:item.Contact.Phones="{ item }">
                <template v-for="(phone, index) in item.Contact.Phones">
                    <div class="d-flex flex-row" :key="index">
                        <span>{{ phone.PhoneType + ': ' + phone.PhoneNumber  }}</span>
                    </div>
                </template>
            </template>
        </v-data-table>
        <v-data-table
            :headers="invoiceHeaders"
            :items="PendingInvoices"
            :items-per-page="-1"
            :search="invoiceSearchTerm"
            calculate-widths
            class="appic-table-light specification-table"
            dense
            item-key="Invoice.id"
            show-select
            single-select
            v-model="selectedInvoices"
        >
            <template v-slot:top>
                <div class="d-flex flex-row align-center">
                    <span class="font-weight-bold">{{ $t('message.invoices') }}</span>
                </div>
                <div class="d-flex flex-row mb-3 align-center">
                    <v-text-field
                        :value="invoiceSearchTerm"
                        append-icon="search"
                        class="filter-width-250"
                        dense
                        hide-details="auto"
                        solo
                        @change="invoiceSearchTerm = $event"
                    ></v-text-field>
                    <v-btn class="ml-3" @click="resetInvoiceSearch">{{ $t('message.clear') }}</v-btn>
                    <v-btn class="ml-3 info lighten-2 text--darken-1 black--text" @click="uploadInvoicesToXero()" :disabled="isAuthorized == false || (isAuthorized == true && PendingContacts.length > 0)">{{ $t('message.integrateSelection') }}</v-btn>
                    <span class="ml-3">{{ $t('message.integrateContactsFirst') }}</span>
                </div>
            </template>
            <template v-slot:item.Invoice.Date="{ item }">
                {{ item.Invoice.Date != '0000-00-00' ? formatDate(item.Invoice.Date) : '' }}
            </template>
            <template v-slot:item.Invoice.DueDate="{ item }">
                {{ item.Invoice.DueDate != '0000-00-00' ? formatDate(item.Invoice.DueDate) : '' }}
            </template>
            <template v-slot:item.Invoice.LineItems="{ item }">
                <v-simple-table class="appic-table-light simple-table my-2" style="width: 400px">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="pl-1" style="background-color: unset !important; height: 20px !important;">ItemCode</th>
                                <th class="pl-1" style="height: 20px !important;">Description</th>
                                <th class="pl-1" style="height: 20px !important;">Quantity</th>
                                <th class="pl-1" style="height: 20px !important;">UnitAmount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="(item, index) in item.Invoice.LineItems">
                                <tr :key="index">
                                    <td style="height: 20px !important;">{{ item.ItemCode }}</td>
                                    <td style="height: 20px !important;">{{ item.Description }}</td>
                                    <td style="height: 20px !important;">{{ item.Quantity }}</td>
                                    <td style="height: 20px !important;">{{ item.UnitAmount }}</td>
                                </tr>
                            </template>
                        </tbody>
                    </template>
                </v-simple-table>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import {mapFields, mapMultiRowFields} from "vuex-map-fields";
import {api} from "Api";
import {formatDate} from "Helpers/helpers";

export default {
    name: "XeroPendingIntegrationList",
    data(){
        return {
            contactSearchTerm: null,
            invoiceSearchTerm: null,
            pageKey: 0,
            selectedContacts: [],
            selectedInvoices: []
        }
    },
    computed: {
        ...mapMultiRowFields('xero',{
            PendingContacts: 'PendingContacts',
            PendingInvoices: 'PendingInvoices'
        }),
        ...mapFields('xero',{
            isAuthorized: 'isAuthorized'
        }),
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
        contactHeaders () {
            let headers = [
                {
                    id: 1,
                    text: 'Organisation',
                    value: 'Contact.Organisation',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    sortable: false
                },
                {
                    id: 2,
                    text: 'Name',
                    value: 'Contact.Name',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    sortable: false
                },
                {
                    id: 3,
                    text: 'ContactNumber',
                    value: 'Contact.ContactNumber',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    sortable: false
                },
                {
                    id: 4,
                    text: 'AccountNumber',
                    value: 'Contact.AccountNumber',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    sortable: false
                },
                {
                    id: 5,
                    text: 'ContactStatus',
                    value: 'Contact.ContactStatus',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    sortable: false
                },
                {
                    id: 6,
                    text: 'CompanyNumber',
                    value: 'Contact.CompanyNumber',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    sortable: false
                },
                {
                    id: 7,
                    text: 'ContactPersons',
                    value: 'Contact.ContactPersons',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    sortable: false
                },
                {
                    id: 8,
                    text: 'BankAccountDetails',
                    value: 'Contact.BankAccountDetails',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    sortable: false
                },
                {
                    id: 9,
                    text: 'TaxNumber',
                    value: 'Contact.TaxNumber',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    sortable: false
                },
                {
                    id: 10,
                    text: 'Addresses',
                    value: 'Contact.Addresses',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    sortable: false
                },
                {
                    id: 11,
                    text: 'Phones',
                    value: 'Contact.Phones',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    sortable: false
                },
                {
                    id: 12,
                    text: 'DefaultCurrency',
                    value: 'Contact.DefaultCurrency',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    sortable: false
                }
            ]
            return headers
        },
        invoiceHeaders () {
            let headers = [
                {
                    id: 1,
                    text: 'Organisation',
                    value: 'Invoice.Organisation',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 2,
                    text: 'Type',
                    value: 'Invoice.AppicInvoiceType',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 3,
                    text: 'InvoiceNumber',
                    value: 'Invoice.InvoiceNumber',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 4,
                    text: 'Contact',
                    value: 'Invoice.Contact',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 5,
                    text: 'LineItems',
                    value: 'Invoice.LineItems',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 6,
                    text: 'Date',
                    value: 'Invoice.Date',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 7,
                    text: 'DueDate',
                    value: 'Invoice.DueDate',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 8,
                    text: 'LineAmountTypes',
                    value: 'Invoice.LineAmountTypes',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 9,
                    text: 'Reference',
                    value: 'Invoice.Reference',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 10,
                    text: 'CurrencyCode',
                    value: 'Invoice.CurrencyCode',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 11,
                    text: 'CurrencyRate',
                    value: 'Invoice.CurrencyRate',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 12,
                    text: 'Status',
                    value: 'Invoice.Status',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                }
            ]
            return headers
        },
        formatDate: () => formatDate
    },
    methods: {
        ...mapActions('xero',{
            getPendingContacts: 'getPendingContacts',
            getPendingInvoices: 'getPendingInvoices',
            resetPendingLists: 'resetPendingLists',
            uploadContacts: 'uploadContacts',
            uploadInvoices: 'uploadInvoices'
        }),
        reconnectToXero() {
            let tab = window.open(process.env.VUE_APP_XERO_AUTHORIZE_URL)
            tab.name = 'tab_authorize_xero'
            this.openedTabs.push(tab.name)
        },
        resetContactSearch() {
            this.contactSearchTerm = null
        },
        resetInvoiceSearch() {
            this.invoiceSearchTerm = null
        },
        async uploadContactsToXero() {
            if(this.selectedContacts.length > 0) {
                if (await this.$root.$confirm(this.$t('message.integrateContacts'), this.$t('message.confirmations.continueIntegrateContactsAction'), {color: 'orange'})) {
                    this.uploadContacts(this.selectedContacts)
                        .then((status) => {
                            if(status == 'done'){
                                this.$toast.success(this.$t('message.successes.contactsIntegrated'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                this.resetPendingLists()
                                this.getPendingContacts()
                                    .then(() => {
                                        this.getPendingInvoices()
                                    })
                            } else {
                                this.$toast.error(this.$t('message.errors.contactsNotIntegrated'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                        })
                        .catch((error) => {
                            this.$toast.error( error.message,
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        })
                }
            } else {
                this.$toast.error(this.$t('message.errors.noContactsSelected'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        },
        async uploadInvoicesToXero() {
            if(this.selectedInvoices.length > 0) {
                if (await this.$root.$confirm(this.$t('message.integrateInvoices'), this.$t('message.confirmations.continueIntegrateInvoicesAction'), {color: 'orange'})) {
                    this.uploadInvoices(this.selectedInvoices)
                        .then((status) => {
                            if(status == 'done'){
                                this.$toast.success(this.$t('message.successes.invoicesIntegrated'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                this.resetPendingLists()
                                this.getPendingContacts()
                                    .then(() => {
                                        this.getPendingInvoices()
                                    })
                            } else {
                                this.$toast.error(this.$t('message.errors.invoicesNotIntegrated'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                        })
                        .catch((error) => {
                            this.$toast.error( error.message,
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        })
                }
            } else {
                this.$toast.error(this.$t('message.errors.noInvoicesSelected'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        }
    },
    created() {
        this.isAuthorized = false
        let _this = this

        this.$nextTick(function () {
            let intervalId = setInterval(function(){
                let promise = new Promise((resolve, reject) => {
                    api
                        .get('/xero/appic-authorized')
                        .then(response => response.data.status)
                        .then(response => resolve(response))
                        .catch(error => reject(error))
                })
                promise
                    .then(status => {
                        if(status == 'success'){
                            _this.isAuthorized = true
                        }
                        clearInterval(intervalId)
                    })
            },1000)
        })
    },
    mounted() {
        this.resetPendingLists()
        this.getPendingContacts()
            .then(() => {
                this.getPendingInvoices()
            })
    }
}
</script>

<style scoped>
.filter-width-250 {
    width: 250px !important;
    max-width: 250px !important;
}

.v-alert >>> .theme--light.v-icon {
    color: white !important;
}

.v-data-table >>>  th:first-child {
    background-color: #c5e1a5 !important;
    border-color: #c5e1a5 !important;
}

.v-data-table >>> td {
    padding: 0 3px !important;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
}
.v-data-table >>> td:first-child {
    padding-left: 3px !important;
}
.v-data-table >>> .v-data-table-header th {
    white-space: nowrap;
}
</style>